export const socialData = [
  {
    id: 0,
    platform: 'Google'
  },
  // {
  //   id: 1,
  //   platform: 'Facebook'
  // },
  {
    id: 2,
    platform: 'Apple'
  },
  // {
  //   id: 3,
  //   platform: 'Twitter'
  // },
]