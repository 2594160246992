import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom"

import css from './styles.module.scss';

import PageTitle from '../../components/PageTitle/PageTitle';
import Ticket from '../../components/Ticket/Ticket';
import IconClaim from '../../components/Icons/Claim';
import IconCheck from '../../components/Icons/Check';
import PowBlock from '../../components/PowBlock/PowBlock';
import ImaxClient from '../../imaxClient';

import moment from 'moment';
import DateHelper from '../../scripts/DateHelper';

const ClaimPOW = (props) => {
  console.log(props);
  const history = useHistory();
  const reminderRef = React.createRef()

  const [localDate, setLocalDate] = useState(DateHelper.utc());

  const [noticeVisible, setNoticeVisible] = useState(false);
  const [notice, setNotice] = useState("There was an issue setting the reminder. Try again later.");

  function setupPow() {
    console.log("Detected show: ", props?.detectedShow);

    if (props?.detectedShow) {
      const ldate = DateHelper.convertUTCtoLocalMoment(props?.detectedShow?.show_time);
      console.log("showtime: ", ldate);
      setLocalDate(ldate);
    }
  };

  useEffect(() => {
    document.title = 'IMAX - Claim Your Pow';
    setupPow();

    
  }, []);

  useEffect(() => {
    if (noticeVisible) {
      console.log("scroll");
      reminderRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [noticeVisible, reminderRef]);

  async function claimNFT(e) {
    e.preventDefault();
    try {
      const showRes = await ImaxClient.mintNFT(props?.detectedShow)
      console.log(showRes);
      if (showRes) {
        history.push('./collection', this?.props.location.state);
      } else {
        alert("There was an error loading the POW - " + showRes.err);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function setReminder(e) {
    e.preventDefault();
    console.log("Setting reminder");
    //alert("We'll email you later to remind you.  If it doesn't make it to your inbox you can return to <a href='https://x.imax.com' target='_blank'>x.imax.com</a> at any time to sign in and finish the claim process.");
    try {
      const showRes = await ImaxClient.reserveNFT(props?.detectedShow)
      console.log(showRes);
      if (showRes) {
        //history.push('./collection', this?.props.location.state);
        setNoticeVisible(true);
        // setNotice(`We'll email you later to remind you.  If it doesn't make it to your inbox you can return to <a href='https://x.imax.com' target='_blank'>x.imax.com</a> at any time to sign in and finish the claim process.`);
      } else {
        alert("There was an error loading the POW - " + showRes.err);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function onGoToCollection(e) {
    history.push("/collection");
  }

  // const reminderNotice = isReminderVisible ?? {{ <p className={`text-center`}> We'll email you later to remind you.  If it doesn't make it to your inbox you can return to <a href='https://x.imax.com' target='_blank'>x.imax.com</a> at any time to sign in and finish the claim process.</p>
  // }} : "";

  return (
    <main>

      <section id={css.claimPow} className={`container-fluid px-0`}>
        <PageTitle>
          <h1>Your POW is ready to claim!</h1>
          <p>Claim your POW and start reaping the rewards of your fandom.</p>
        </PageTitle>

        <PowBlock>
          <Ticket
            size='lg'
            title={props.detectedShow?.nft_name}
            abbr={props.detectedShow?.nft_abbr}
            date={localDate?.format('MMM Do YYYY')}
            theater={props.detectedShow?.theater_name}
            showtime={localDate?.format('h:mm a')}
            edition='12'
            available='2000'
            rotate='-10.99' />
        </PowBlock>

        <div className={css.btnActions}>
          <Button
            variant='primary'
            className={`w-100 btn-circ`}
            onClick={(e) => { claimNFT(e); }}
          >
            <div className={css.iconHolder}><IconClaim /></div>
            Claim POW
          </Button>

          <a href="#" 
            onClick={ (e) => { setReminder(e); } } ref={reminderRef} className={css.remind}>Remind Me to Claim Later
            </a>
          {/* <a href='#' onClick={() => console.log('click')} className={css.set}><IconCheck cl='me-3' />Reminder Set</a> */}
        </div>

        { noticeVisible && <p className={`text-center`}> We'll email you later to remind you.  If it doesn't make it to your inbox you can return to <a href='https://x.imax.com' target='_blank'>x.imax.com</a> at any time to sign in and finish the claim process.
        <br />
        <Button onClick={ (e) => { onGoToCollection(e); }}>Go To My Collection</Button>
        </p> }

      </section>

    </main>
  )
}

export default ClaimPOW

