import React from 'react'

import css from './styles.module.scss'

const LoaderVerify = props => {

  const {
    width = '0',
    cl = ''
  } = props

  console.log(cl)

  const getWidth = width => {
    if (width !== '0') {
      return `calc(${width}% + 2px)`
    }
    return '0'
  }

  return (
    <div className={css.loaderVerify}>
      <div className={`${css.status} ${css[cl]}`} style={{ width: getWidth(width) }} />
    </div>
  )
}

export default LoaderVerify