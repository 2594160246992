import React from 'react'
import Nav from 'react-bootstrap/Nav'

const NavPills = props => {

  const {
    showContent = 'claimed',
    setShowContent = () => {},
    showBadge = false,
  } = props

  return (
    <Nav variant='pills'>
      <Nav.Item>
        <Nav.Link active={showContent === 'claimed' ? true : false} onClick={() => setShowContent('claimed')}>Claimed</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link active={showContent === 'unclaimed' ? true : false} onClick={() => setShowContent('unclaimed')}>
          Unclaimed
          { showBadge && <div className={`dot`} /> }
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        {/* <Nav.Link active={showContent === 'upcoming' ? true : false} onClick={() => setShowContent('upcoming')}>
          Upcoming
          <div className={`dot`} />
        </Nav.Link> */}
        <Nav.Link active={showContent === 'xPass' ? true : false} onClick={() => setShowContent('xPass')}>
          xPass
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export default NavPills