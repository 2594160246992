import React from 'react'

const IconTwitter = props => {

  const { cl = '' } = props

  return (
    <svg className={cl} width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.54752 19.7507C16.6042 19.7507 21.5578 12.2473 21.5578 5.74046C21.5578 5.52734 21.5578 5.31518 21.5434 5.10398C22.507 4.40693 23.3389 3.54386 24 2.55518C23.1014 2.95358 22.148 3.21473 21.1718 3.3299C22.1998 2.71459 22.9692 1.74668 23.3366 0.606379C22.3701 1.17999 21.3126 1.58421 20.2099 1.80158C19.4675 1.01216 18.4856 0.48942 17.4162 0.314263C16.3468 0.139107 15.2494 0.321294 14.294 0.832632C13.3385 1.34397 12.5782 2.15595 12.1307 3.14293C11.6833 4.1299 11.5735 5.23684 11.8186 6.29246C9.8609 6.19426 7.94576 5.68549 6.19745 4.79918C4.44915 3.91288 2.90676 2.66884 1.6704 1.14782C1.04073 2.23181 0.847872 3.51505 1.1311 4.73624C1.41433 5.95744 2.15234 7.02477 3.19488 7.72094C2.41123 7.69798 1.64465 7.48657 0.96 7.10462V7.16702C0.960311 8.30387 1.35385 9.40561 2.07387 10.2854C2.79389 11.1652 3.79606 11.7688 4.9104 11.9939C4.18548 12.1916 3.42487 12.2205 2.68704 12.0784C3.00181 13.0567 3.61443 13.9122 4.43924 14.5253C5.26405 15.1384 6.25983 15.4785 7.28736 15.4979C6.26644 16.3004 5.09731 16.8937 3.84687 17.2439C2.59643 17.5941 1.28921 17.6943 0 17.5389C2.25183 18.9839 4.87192 19.7504 7.54752 19.7469' fill='#1DA1F2'/>
    </svg>
  )
}

export default IconTwitter