import React from 'react'

const IconCheckCircle = props => {

  const {
    cl = '',
    color = '#98A2B3'
  } = props

  return (
    <svg className={`position-absolute ${cl}`} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.24999 10L8.74999 12.5L13.75 7.5M18.3333 10C18.3333 14.6024 14.6024 18.3333 9.99999 18.3333C5.39762 18.3333 1.66666 14.6024 1.66666 10C1.66666 5.39763 5.39762 1.66667 9.99999 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10Z' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}

export default IconCheckCircle