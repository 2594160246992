import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
//import ReactDOM from 'react-dom';

const appStyle = {
    height: '500px',
    display: 'flex-basis',
    flexDirection: 'column'
};

const formStyle = {
    margin: '0 auto',
    padding: '10px',
    border: '1px solid #c9c9c9',
    borderRadius: '5px',
    background: '#f5f5f5',
    width: '220px',
    height: '300px',
    display: 'block',
};

const labelStyle = {
    margin: '10px 0 5px 0',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '15px',
};

const inputStyle = {
    margin: '5px 0 10px 0',
    padding: '5px', 
    border: '1px solid #bfbfbf',
    borderRadius: '3px',
    boxSizing: 'border-box',
    width: '100%'
};

const submitStyle = {
    margin: '10px 0 0 0',
    padding: '7px 10px',
    border: '1px solid #efffff',
    borderRadius: '3px',
    background: '#3085d6',
    width: '100%', 
    fontSize: '15px',
    color: 'white',
    display: 'block'
};

const errorMsgStyle = {
    display: 'block',
    color: 'red',
    textWeight: "bold",
    fontSize: '2rem',
    textAlign: 'center',
    margin: 'auto',
    padding: '10px',
    width: '220px',
    height: '30px'
}

const loginTextStyle = {
    display: 'block',
    color: 'black',
    textWeight: "bold",
    fontSize: '2rem',
    textAlign: 'center',
    margin: 'auto',
    padding: '10px',
    width: '220px',
    height: '50px'
}

const Field = React.forwardRef(({label, type}, ref) => {
    return (
      <div>
        <label style={labelStyle} >{label}</label>
        <input ref={ref} type={type} style={inputStyle} />
      </div>
    );
});

const u = "imax"; // not meant to be secure for now. just enough to confuse average user.
const p = "ximaxpow";
const Form = ({onSubmit}) => {
    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };
        onSubmit(data);
    };
    return (
      <form style={formStyle} onSubmit={handleSubmit} >
        <Field ref={usernameRef} label="Username:" type="text" />
        <Field ref={passwordRef} label="Password:" type="password" />
        <div>
          <button style={submitStyle} type="submit">Submit</button>
        </div>
      </form>
    );
};

const keyName = "tempImaxPass";
const setSession = (data) => {
    sessionStorage[keyName] = "ok"; //JSON.stringify(data);
}

export function isPasswordSet() {
    console.log(sessionStorage[keyName]);
    let data = sessionStorage[keyName];
    // var match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    if (!data) return false;
    return true;
}

const SimpleLogin = () => {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState(false);

    const handleSubmit = data => {
        const json = JSON.stringify(data, null, 4);
        //console.clear();
        //console.log(json);
        if (data.username.toLowerCase() === u 
        && data.password.toLowerCase() === p) {
            console.log("Password accepted");
            setSession(data);
            window.location.reload(false);
        } else {
            setErrorMsg(true);
        }
    };
    return (
      <div style={appStyle}>
        <div style={loginTextStyle}>Please login.</div>
        <Form onSubmit={handleSubmit} />
        {errorMsg && <div style={errorMsgStyle}>The username or password is incorrect.</div>}
      </div>
    );
};

export default SimpleLogin;
