import React from 'react'

import css from './styles.module.scss'

const PowBlock = ({ children }) => (
  <div className={css.powBlock}>
    {children}
  </div>
)

export default PowBlock