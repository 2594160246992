import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import css from '../styles.module.scss'
import { useHistory } from 'react-router-dom'

import PageTitle from '../../../components/PageTitle/PageTitle'
import IconLocation from '../../../components/IconLocation/IconLocation'
import IconX from '../../../components/Icons/X'
import IconRefresh from '../../../components/Icons/Refresh'
import IconArrowTopRight from '../../../components/Icons/ArrowTopRight'

const LocationWrong = () => {
  const history = useHistory();

  return (
    <>
      <PageTitle>
        <h1>Hmm. We can't find you...</h1>
        <p>You're outside of the required area to claim this POW. You can read our FAQs to learn more.</p>
      </PageTitle>

      <IconLocation cl='error' icon={<IconX />}>
        <p>Your Location is Outside of the Required Area</p>
      </IconLocation>

      <ButtonGroup className={`d-flex w-100 ${css.btnActions}`}>
        <Button variant='secondary' onClick={() => history.push("/create-account")}>
          <IconRefresh cl={`me-3`} />
          Try Again
        </Button>

        <Button variant='primary' onClick={() => console.log('learn more click')}>
          <IconArrowTopRight cl={`me-3`} />
          Learn More
        </Button>
      </ButtonGroup>

      <p className='mt-3 mx-auto text-center'>
        Need Help?<br />
        <a href='#' onClick={() => console.log('click')}>Contact Support</a>
      </p>
    </>
  )
}

export default LocationWrong