import React, { useState, useEffect } from 'react'

import css from './styles.module.scss'

import DateHelper from '../../scripts/DateHelper';
import ImageProvider from '../../scripts/ImaxImageProvider.js';

const TicketPow = props => {
  const {
    data = []
  } = props

  const stub = ImageProvider.getImageForNFT(data?.nft_abbr);

  const [localDate, setLocalDate] = useState(DateHelper.utc());
  //console.log(data)

  function setupPow() {
    if (data) {
      const ldate = DateHelper.convertUTCtoLocalMoment(data?.show_time);
      setLocalDate(ldate);
    }
  }
  
  useEffect(() => {
    setupPow();
  }, []);

  return (
    <div className={css.ticket}>
      <img src={stub} alt='' />
      {/* <img src={imaxLogo} alt='' className={css.imaxLogo} /> */}

      <div className={css.content}>
        {/* <div className={css.title}>{data.nft_abbr}</div> */}

        <div className={css.roll}>
          <div className={`${css.item} ${css.movie}`}>
            <p className={css.label}>Movie</p>
            <p className={css.entry}>{data.nft_name}</p>
          </div>

          {/* <div className="break" style={{flexBasis: '100%',height: 0}}></div> */}
          {/* <div className={css.item}>
            <p className={css.label}>Edition</p>
            <p className={css.entry}>37 / 3045</p>
          </div> */}

          <div className={css.item}>
            <p className={css.label}>Date</p>
            <p className={css.entry}>{localDate?.format('MMM Do YYYY')}</p>
          </div>

          <div className={css.item}>
            <p className={css.label}>Showtime</p>
            <p className={css.entry}>{localDate?.format('h:mm a')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketPow
