import React, { useState, useEffect, router } from 'react';
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom"

import LocationUtility from "../../../scripts/LocationUtility"
import IconMarker from '../../../components/Icons/Marker'
import CheckList from '../../../components/CheckList/CheckList'
import PageTitle from '../../../components/PageTitle/PageTitle'
import ImaxClient from '../../../imaxClient'

const CreateAccountSuccess = props => {
  const {
    type = '0',
  } = props

  console.log('-- Success')
  const history = useHistory();

  useEffect (() => {
    document.title = 'IMAX - Enable Location';
    props?.setLoading(true);
    checkLocation()
  }, []);

  const checks = {
    title: `How We'll Use This Data`,
    items: [
      { id: 0, checked: 1, copy: 'We do this' },
      { id: 1, checked: 1, copy: 'And this' },
      { id: 2, checked: 1, copy: 'And this' },
      { id: 3, checked: 0, copy: 'But not this' },
      { id: 4, checked: 0, copy: 'And not this either' },
    ]
  }


  async function checkLocation() {
    try {
       await skipPageIfLocationKnown();
    } catch(err) {
      console.log("Error getting location info");
      console.log(err);
      props?.setLoading(false);
    }
  }

  function error(error) {
    var message = LocationUtility.getErrorMessage(error);
    console.log(message);
    props?.setLoading(false);
  }

  async function skipPageIfLocationKnown(e) {
    //if (e) { e.stopPropagation(); }
    var isLocationAllowed = await LocationUtility.isLocationEnabled();
    if (isLocationAllowed) {
      //alert("location search allowed! ");
      return loadLocationAndShows(e);
    }
    props?.setLoading(false);
  }


  async function loadLocationAndShows(e) {
    //if (e) {e.stopPropagation();}
    if (("geolocation" in navigator) === false) { return; }

    props?.setLoading(true);
    navigator.geolocation.getCurrentPosition(function(position) {

      async function getShowData() {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        console.log("Available");

        // get the show
        const showRes = await ImaxClient.getShow(position.coords.longitude, position.coords.latitude)
        if (showRes) {
          console.log("show search: ", showRes);
          props.setDetectedShow(showRes.theater)
          
          history.push("/location", this?.props.location.state)
        }
        props?.setLoading(false);
      }
      getShowData();
      
    }, error, LocationUtility.locationOptions);
  }

  return (
    <>
      <PageTitle>
        <h4>Account successfully created</h4>
        <h1>Almost Done! Verify your location to claim your POW.</h1>
        <p>Next, you'll need to allow us to know your location in order to deliver your POW collectible.</p>
      </PageTitle>

      <Button type='submit' variant='primary' onClick={async (e) => loadLocationAndShows(e) } className={`align-items-center d-flex justify-content-start w-100 btn-circ`}>
        Enable Location Services
        <div className='align-items-center d-flex justify-content-center icon'><IconMarker /></div>
      </Button>

      <CheckList
        checks={checks}
        type={type} />

      <p className='mt-3 mx-auto text-center'>Why do we need your location?<br /><a href='#' onClick={() => console.log('click')}>Read Our FAQs</a></p>
    </>
  )
}

export default CreateAccountSuccess
