import React, { useState, useEffect } from 'react';
import AppProvider from './_context/app';
import { Route, useHistory } from 'react-router-dom';
import Home from './Pages/Home/Home';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { ConnectExtension } from '@magic-ext/connect';
//import {ethers} from 'ethers';


// components

// pages

// styles
import './App.scss';
import CreateAccountSwitch from './Pages/CreateAccount/CreateAccountSwitch';
import LocationSwitch from './Pages/Location/LocationSwitch';
import Confirmation from './Pages/Confirmation/Confirmation';
import ClaimPOW from './Pages/ClaimPOW/ClaimPOW';
import Claiming from './Pages/Claiming/Claiming';
import ClaimSuccess from './Pages/ClaimSuccess/ClaimSuccess';
import PowCollectionSwitch from './Pages/PowCollection/PowCollectionSwitch';
import AccountSettings from './Pages/AccountSettings/AccountSettings';
import Header from './components/Header/Header';
import Error from './Pages/Error/Error';
import Faqs from './Pages/Faqs/Faqs';
import Navigation from './components/Navigation/Navigation';
import SimpleLogin, { isPasswordSet } from './Pages/SimpleLogin/SimpleLogin';
import MagicUtility from './scripts/MagicUtil';
// Analytics
//import ReactGA from 'react-ga';

const TRACKING_ID = "UA-111111111-1";

//ReactGA.initialize(TRACKING_ID);
//ReactGA.pageview(window.location.pathname + window.location.search);

function Routing() {
  const history = useHistory();

  // state
  const [onboardFlow, setOnboardFlow] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [detectedShow, setDetectedShow] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [magic, setMagic] = useState((new Magic(MagicUtility.MAGIC_PUBLICKEY, {
    extensions: [new OAuthExtension(), new ConnectExtension()],
  })));


  async function setUpMagic() {
    console.log("Setting up magic")
    let u = null;
    try {
      if (await magic?.user.isLoggedIn()) {
        //console.log("3");
        u = await magic?.user?.getMetadata();
        setIsLoggedIn(true);
        //console.log("4 - metadata ", u);
      } else {
        //console.log("Not logged in")
        setIsLoggedIn(false);
      }
    } catch(err) {
      //setIsLoggedIn(false);
      console.log("Error getting user metadata ", err);
    }

    //console.log("User: ", u);
    setUser(u);
    await setupOAuth();
    //console.log("Magic: 1", magic);
  }

  async function setupOAuth() {
    if (window.location.pathname === '/oauth') {
      try {
        console.log('here')
        console.log(magic);
        const result = await magic?.oauth.getRedirectResult();
        console.log(result);
        const profile = JSON.stringify(result.oauth.userInfo, undefined, 2);
        if (profile.email) {
          console.log(profile.email)
          setUser(profile.email);
          setIsLoggedIn(true);
          //router.push('/');
        }
      } catch (err) {
        console.log(err);
        setIsLoggedIn(false);
        //window.location.href = window.location.origin;
        //throw new Error('Oauth login failed');
      }
      //console.log(await magic?.oauth.getRedirectResult());
    } else {
      setIsLoggedIn(true);
    }
  }

  async function logout() {
    console.log("Logging out - ", magic?.user?.email);
    await magic?.user?.logout();
    setUser(null);
    setDetectedShow(null);
    setIsLoggedIn(false);
    setShowMenu(false);
    history.push("/");
  }

  useEffect(() => {
    async function _setUpMagic() {
      console.log("Setting up magic")
      if(!magic) {
        await setUpMagic()
      }  
    }
    _setUpMagic();

    // console.log("isLoggedIn: " + isLoggedIn);
    // console.log("isUser: ", !(user === null));
    // console.log("isMagic: ", !(magic === null));
  }, []);

  // check for simple login
  let simpleLogin = isPasswordSet();
  if (!simpleLogin) {
    return ( <SimpleLogin /> )
  } 

  // load regular app
  return (
    <AppProvider>
      <div className="non-footer">
        
        <div id="PageContainer">
          <Navigation showMenu={showMenu} setShowMenu={setShowMenu} magic={magic} user={user} logout={logout} isLoggedIn={isLoggedIn} />
          <Header showMenu={showMenu} setShowMenu={setShowMenu} />

          {/* <Route exact path="/">{Landing()}</Route> */}
          
          <Route exact path="/">
            <Home user={user} setUser={setUser} />
          </Route>
          <Route exact path="/oauth"><Home user={user} setUser={setUser} /></Route>

          <Route exact path='/create-account'>
            <CreateAccountSwitch 
              user={user} 
              setUser={setUser} 
              setDetectedShow={setDetectedShow}
              magic={magic}  
              setIsLoggedIn={setIsLoggedIn}
            />
          </Route>

          <Route exact path='/location'>
            <LocationSwitch user={user} setUser={setUser} 
              setDetectedShow={setDetectedShow} 
              detectedShow={detectedShow}
              magic={magic}
              />
          </Route>

          <Route exact path='/confirmation'>
            <Confirmation user={user} setUser={setUser} detectedShow={detectedShow}/>
          </Route>

          <Route exact path='/claim-pow'>
            <ClaimPOW user={user} setUser={setUser} detectedShow={detectedShow}/>
          </Route>

          <Route exact path='/claiming'>
            <Claiming user={user} setUser={setUser}/>
          </Route>

          <Route exact path='/claim-success'>
            <ClaimSuccess user={user} setUser={setUser}/>
          </Route>

          <Route exact path='/collection'>
            <PowCollectionSwitch user={user} setUser={setUser} setDetectedShow={setDetectedShow} />
          </Route>

          <Route exact path='/account-settings'>
            <AccountSettings user={user} setUser={setUser}/>
          </Route>

          <Route exact path='/error'>
            <Error />
          </Route>

          <Route exact path='/faqs'>
            <Faqs />
          </Route>
          <Route exact path="/oauth">
            <Home/>
          </Route>

          {/* <Route exact path='/terms' component={Terms} />
          <Route exact path='/privacy-policy' component={Privacy} /> */}
          
          {/* <Route exact path='/profile'>
            <Profile/> 
          </Route> */}
        </div>

      </div>

    </AppProvider>
  )
}

export default function App () {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}
