import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import { Magic } from 'magic-sdk';
import MagicUtility from './scripts/MagicUtil';

export default class ImaxClient {
    //static const apiBaseUrl = 'https://immbms.themainbranch.com';

    static async getHeaders() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    
        const m = (new Magic(MagicUtility.MAGIC_PUBLICKEY));
          console.log("2");
          let u = null;
          try {
            if (await m?.user.isLoggedIn()) {
              console.log("3");
              u = await m?.user?.getIdToken();
              //console.log("Token: ", u);
              headers['Authorization'] = 'Bearer ' + u
            } else {
              console.log("Not logged in")
            }
          } catch(err) {
            console.log("Error getting user metadata");
            console.log(err);
          }

        return headers;
      }

    static async standardFetch(url) {
        const headers = await this.getHeaders();
        const response = await fetch(url, { headers });
    
        var body;
        if (response.ok) {
          body = await response.json(); 
        }
        
        return { response, body };
    }
    
    static async getShow(longitude, latitude) {
        let resp = await this.standardFetch(`/api/v1/theaters/by_location?longitude=${longitude}&latitude=${latitude}`);
        if(resp.response.ok) {
            const inUse = resp.body;
            return (inUse == null || inUse === undefined) ? true : inUse;
        }
      }

      static async mintNFT(detectedShow) {
        const requestBody = { detectedShow };
        const response = await fetch('/api/v1/theaters/mint',
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers:await this.getHeaders()
        });

        const body = await response.json();

        return { response, body };  
        
      }

      static async reserveNFT(detectedShow) {
        const requestBody = { detectedShow };
        const response = await fetch('/api/v1/theaters/reserve',
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers:await this.getHeaders()
        });

        const body = await response.json();

        return { response, body };        
      }

      static async getMyCollection() {
        const res = await fetch(`/api/v1/collection`, {
          method: 'GET',
          headers: await this.getHeaders()
        });

        if (res.status === 200) {
          let collection = await res.json();
          return collection;
        } else {
          throw Error({ error: res.err });
        }
      }


}
