import React from 'react'
import Button from 'react-bootstrap/Button'
//import { unclaimedPows } from './plcData'
import { useHistory } from 'react-router-dom'

import ImaxImageProvider from '../../../scripts/ImaxImageProvider'

import css from './styles.module.scss'

const CollectionUnclaimed = (props) => {
  // const {
  //   setUser = () => {},
  //   setDetectedShow = () => {}
  // } = props

  //const data = unclaimedPows
  const history = useHistory();

  const data = props?.nftCollection;
  if (!data) { return null; }

  const onClaimClick = function(nftDetails) {
    if (!nftDetails) { return null; };
    console.log("Claim this reserved nft: ");
    props?.setDetectedShow(nftDetails);
    history.push("/confirmation");
  };

  const powsEls = data.map(li => {
    console.log("li: ", li);
    const bg = ImaxImageProvider.getSmallImage(li.nft_abbr);
    const abbr = li.nft_abbr.substring(0, 2);
    return (
      <li key={li.n_id} className={css.item}>
        <div className={`${css.icon} ${css[bg]}`}>{abbr}</div>
        <p className={`me-auto`}>{li.nft_name}</p>
        <Button variant='primary' value={li} onClick={() => onClaimClick(li)}>Claim</Button>
      </li>
    )
  })

  return (
    <ul id={css.unclaimedList}>
      {powsEls}
    </ul>
  )
}

export default CollectionUnclaimed