import img1 from '../../img/token1.jpg'
import img2 from '../../img/token2.jpg'
import img3 from '../../img/token3.jpg'

export const artData = [
  {
    id: 0,
    cl: 'me-auto',
    copy: 'Claim',
    img: img1,
    height: 62,
    width: 62,
    color1: '#CD16AE',
    color2: '#FD6139',
    top: '2rem',
    right: '-7rem',
    loc: 'right',
  },
  {
    id: 1,
    cl: 'ms-auto',
    copy: 'Your',
    img: img2,
    height: 72,
    width: 122,
    color1: '#74277D',
    color2: '#6D4A81',
    top: '1rem',
    left: '-15rem',
  },
  {
    id: 2,
    cl: 'me-auto',
    copy: 'POW',
    img: img3,
    height: 115,
    width: 115,
    color1: '#0F80B9',
    color2: '#1DA4E9',
    right: '-14rem',
    loc: 'right',
  },
]