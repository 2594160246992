import React from 'react'

import css from './styles.module.scss'

import IconCheckList from '../Icons/CheckList'

const CheckList = props => {
  const {
    checks = {},
    type = '0',
  } = props

  const checkEls = checks.items.map(ch => (
    <li key={ch.id} className={`success ${ch.checked ? css.success : null}`}>
      <IconCheckList success={ch.checked} type={type} />{ch.copy}
    </li>
  ))

  return (
    <div className={`w-100`}>
      {checks.title && <h4 className='text-center'>{checks.title}</h4>}

      <ul id={css.checkList}>
        {checkEls}
      </ul>
    </div>
  )
}

export default CheckList