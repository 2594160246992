import React from 'react'

import css from './styles.module.scss'

import imgSupport from '../../img/support.png'
import IconEnvelope from '../Icons/Envelope'

const ContactSupport = () => {
  return (
    <div id={css.supportBlock}>
      <img src={imgSupport} alt='' />

      <div className={css.content}>
        <h3>Still have questions?</h3>
        <p>Can't find the answer you're looking for? Please chat to our friendly team.</p>
      </div>

      <button type='button' className={`btn btn-primary w-100`} onClick={() => console.log('click')}>
        <IconEnvelope cl='me-3' />
        Contact Support
      </button>
    </div>
  )
}

export default ContactSupport