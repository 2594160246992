import avatar from '../img/pow/posters/avatar.png';
import brown from '../img/pow/gradients/text-fstx.png';
import rainbow from '../img/pow/gradients/text-eeao.png';

import dune from '../img/pow/posters/dune.png';
import topgun from '../img/pow/posters/topgun.png';
import creed from '../img/pow/posters/creed3.png';

const imgMap = { 
    "default": rainbow,
    "AVTR": avatar,
    "FSTX": brown,
    "EEAO": rainbow,
    "DUNE": dune,
    "TOPG": topgun,
    "CREE": creed,
};

const smallStyle = {
    "default": "blue",
    "AVTR": "blue",
    "FSTX": "black",
    "EEAO": "purple",
    "DUNE": "brown",
    "TOPG": "skyblue",
    "CREE": "red",
}

export default class ImaxImageProvider {

    static  getImageForNFT(abbr) {
        return imgMap[abbr] ?? rainbow;
    }

    static getSmallImage(abbr) {
        return smallStyle[abbr] ?? "blue";
    }
}
