import React from 'react'
import IconCheckCircle from './CheckCircle'
import IconX from './X'

const IconCheckList = props => {

  const {
    success = '',
    type = '0',
  } = props

  const getStroke = success => {
    const colors = {
      0: '#98A2B3',
      1: '#039855',
      'default': '#475467',
    }
    return (colors[success] || colors['default'])
  }

  const getIconAndColor = (type, success) => {

    console.log(success)
    
    if (type === '0') {
      return <IconCheckCircle color={getStroke(success)} />
    }

    if (success === 1) {
      return <IconCheckCircle color={getStroke('default')} />
    }
    return <IconX color={getStroke(0)} />
  }

  return (
    <>
      {getIconAndColor(type, success)}
    </>
  )
}

export default IconCheckList