import React from 'react'

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import FormBasic from './FormBasic/FormBasic'
import FormPassword from './FormPassword/FormPassword'

const AccountSettings = () => {

  const classes = `container-fluid d-flex flex-column px-0`

  return (
    <main>

      <section id={css.settings} className={classes}>
        <PageTitle>
          <h1>Account Settings</h1>
        </PageTitle>

        <FormBasic />

        <FormPassword />
      </section>

    </main>
  )
}

export default AccountSettings