import React from 'react'

// import css from './styles.module.scss'

const PageTitle = ({ children }) => {

  return (
    <div>
      {children}
    </div>
  )
}

export default PageTitle