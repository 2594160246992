import React from 'react'

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import { faqsData } from './data'
import FaqAccordion from './Accordion/Accordion'
import ContactSupport from '../../components/ContactSupport/ContactSupport'

const Faqs = () => {

  return (
    <main>

      <section id={css.faqs} className={`container-fluid px-0`}>
        <PageTitle>
          <h1>Frequently Asked Questions</h1>
          <p>Wondering how this whole thing works?  Find your questions answered below.</p>
        </PageTitle>

        <FaqAccordion data={faqsData} />

        <ContactSupport />
      </section>

    </main>
  )
}

export default Faqs