import React from 'react'

const IconEnvelope = props => {

  const {
    cl = ''
  } = props

  return (
    <svg className={cl} width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.6665 3.83337L8.4706 8.59624C9.02158 8.98193 9.29707 9.17477 9.59672 9.24946C9.86142 9.31544 10.1383 9.31544 10.403 9.24946C10.7026 9.17477 10.9781 8.98193 11.5291 8.59624L18.3332 3.83337M5.6665 14.6667H14.3332C15.7333 14.6667 16.4334 14.6667 16.9681 14.3942C17.4386 14.1545 17.821 13.7721 18.0607 13.3017C18.3332 12.7669 18.3332 12.0668 18.3332 10.6667V5.33337C18.3332 3.93324 18.3332 3.23318 18.0607 2.6984C17.821 2.22799 17.4386 1.84554 16.9681 1.60586C16.4334 1.33337 15.7333 1.33337 14.3332 1.33337H5.6665C4.26637 1.33337 3.56631 1.33337 3.03153 1.60586C2.56112 1.84554 2.17867 2.22799 1.93899 2.6984C1.6665 3.23318 1.6665 3.93324 1.6665 5.33337V10.6667C1.6665 12.0668 1.6665 12.7669 1.93899 13.3017C2.17867 13.7721 2.56112 14.1545 3.03153 14.3942C3.56631 14.6667 4.26637 14.6667 5.6665 14.6667Z' stroke='#1D2939' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}

export default IconEnvelope