import React from 'react'

const IconShare = props => {

  const { cl = '' } = props

  return (
    <svg className={cl} width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.00015 11.5V6.50004M6.00015 6.50004H11.0002M6.00015 6.50004L11.0002 11.5M5 16.5H12C13.4001 16.5 14.1002 16.5 14.635 16.2275C15.1054 15.9878 15.4878 15.6054 15.7275 15.135C16 14.6002 16 13.9001 16 12.5V5.5C16 4.09987 16 3.3998 15.7275 2.86502C15.4878 2.39462 15.1054 2.01217 14.635 1.77248C14.1002 1.5 13.4001 1.5 12 1.5H5C3.59987 1.5 2.8998 1.5 2.36502 1.77248C1.89462 2.01217 1.51217 2.39462 1.27248 2.86502C1 3.3998 1 4.09987 1 5.5V12.5C1 13.9001 1 14.6002 1.27248 15.135C1.51217 15.6054 1.89462 15.9878 2.36502 16.2275C2.8998 16.5 3.59987 16.5 5 16.5Z' stroke='#475467' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}

export default IconShare