import React from 'react'
import Accordion from 'react-bootstrap/Accordion'

const FaqAccordion = props => {

  const {
    data = {}
  } = props

  console.log(data)

  const accItemsEls = data.map(item => (
    <Accordion.Item eventKey={item.id} key={item.id}>
      <Accordion.Header>{item.title}</Accordion.Header>
      <Accordion.Body>
        {item.copy}
      </Accordion.Body>
    </Accordion.Item>
  ))

  return (
    <Accordion defaultActiveKey={0}>
      {accItemsEls}
    </Accordion>
  )
}

export default FaqAccordion