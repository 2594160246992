import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { useState, useEffect } from 'react';
import { ConnectExtension } from '@magic-ext/connect';
import {ethers} from 'ethers';

import Button from 'react-bootstrap/Button'

import css from './styles.module.scss';

import { artData } from './plcData';
import BgCircles from '../../components/BgCircles/BgCircles';
import PageTitle from '../../components/PageTitle/PageTitle';
import PosterArtCircle from '../../components/PosterArtCircle/PosterArtCircle';
import IconArrowRight from '../../components/Icons/ArrowRight';
import Loader from '../../components/Loader/Loader';

import { useHistory } from 'react-router-dom';
import MagicUtility from '../../scripts/MagicUtil';

const Home = (props) => {
  let history = useHistory();

  //const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [magic, setMagic] = useState()
  const [loading, setLoading] = useState(false);

  async function saveLoginInformation(didToken) {
    try {
      if (!didToken) {
        throw Error("Missing token. Can't save user");
      }
      const res = await fetch(`/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
        },
      });
    
      if (res.status === 200) {
        // Set the UserContext to the now logged in user
        const userMetadata = await magic?.user.getMetadata();
        //await setUser(userMetadata);
        props.setUser(magic?.user)
        console.log(userMetadata);
      } else {
        console.log(res);
      }
    } catch (err) {
      throw Error(err)
    }
  }  

  async function handleOAuthResponse(magic) {
    try {
      console.log("OAuth response");
      setLoading(true);
      //console.log('here')
      const result = await magic.oauth.getRedirectResult();
      const idToken = result?.magic.idToken;
      if (idToken) {
        //console.log("token: ", idToken);
        await saveLoginInformation(idToken);
        
        //setMagic(magic);
        // NOTE: if I load straight to create-account again an iframe messes things up on /location screen.
        // history.push("/create-account");
        history.replace("/create-account"); 
      } else {
        //history.push("/"); 
        //console.log("what");
      }
    } catch (err) {
      console.log(err);
      //history.push("/"); 
      //window.location.href = window.location.origin;
      
      //throw new Error('Oauth login failed');
    } finally {
      //setLoading(false);
    }
    //console.log(await magic?.oauth.getRedirectResult());
  }
  
  useEffect(() => {
    async function setUpMagic() {
      const magic = (new Magic(MagicUtility.MAGIC_PUBLICKEY, {
        extensions: [new OAuthExtension(), new ConnectExtension()],
      }));
      
      if (window.location.pathname === '/oauth') {
        await handleOAuthResponse(magic);
      } else {
        setMagic(magic);
        console.log("Magic 2: ", magic);
      }
    }

    document.title = 'IMAX - POW';
    setUpMagic() 
   },[]);


  const artEls = artData.map(item => (
    <span key={item.id} className={item.cl}>
      {item.copy}
      <PosterArtCircle
        image={item.img}
        height={item.height}
        width={item.width}
        top={item?.top}
        right={item?.right}
        bottom={item?.bottom}
        left={item?.left}
        loc={item?.loc} />
    </span>
  ))

  const onClaimClick = () => {
      window.location="/create-account";
  }
    
  if (loading) {
    return ( <main id='main'>
              <Loader />
             </main> )
  };

  return (
    <main id='main'>
      
      {/* <div id='magicButtonsBlock'>
        <button onClick={() => {callMagic();}}>Click Magic!</button>
        {<button onClick={() => {callGoogleProvider();}}>Click Google OAuth!</button> }
        <button onClick={() => {signMagicWalletForEthPass();}}>Click EthPass!</button>
      </div> */}

      <BgCircles />

      <section id={css.landing} className='container-fluid px-0'>
        <div className='d-flex flex-column'>
          <div className={css.betaPill}>BETA</div>

          <div className={css.title}>
            {artEls}
          </div>

          <div className={css.content}>            
            <PageTitle>
              <h1>Biggest Screen. Best Rewards.</h1>
              <p>You're a few steps away from unlocking another unbelievable benefit of seeing the movies you love in the world's most immersive experience.</p>
            </PageTitle>

            <Button variant='primary' className='btn-circ' onClick={onClaimClick}>
              <div className={css.iconHolder}><IconArrowRight /></div>
              Claim Now
            </Button>

            <a href='#' onClick={() => console.log('click')} className={`btn btn-link ${css.btnLink}`}>Learn More</a>
          </div>
        </div>
      </section>

    </main>
  )
}



// async function callMagic () {
//   try {
//     if (magic === null) {
//       throw Error("Magic is null!");
//     }
//     const didToken = await magic.auth.loginWithMagicLink({email: 'david@themainbranch.com'});
//     if (didToken) {
//       await saveLoginInformation(didToken);
//     }
    
//     console.log('Done')
//   } catch (err) {
//     console.log(err);
//   }
// }
// magic.auth.loginWithMagicLink({ email: 'test+fail@magic.link' });
// magic.auth.loginWithMagicLink({
//     email:
//       'test+success_with_{0x89A3983da27fF0eFCF901F74C4df84e0450A17B7:0x19de850af732e9e5745915162d707d6d8cf013ce7b2862e93081b0c8883bdfae}@magic.link',
//   });

// {
  //   'sub': '100912856983882887003',
  //   'name': 'David Scott',
  //   'familyName': 'Scott',
  //   'givenName': 'David',
  //   'profile': 'https://plus.google.com/100912856983882887003',
  //   'picture': 'https://lh3.googleusercontent.com/a/AEdFTp61I_p0Tak4ibmNTeVzASHpwPFSPL1Ta2dW0Y7L=s96-c',
  //   'locale': 'en',
  //   'email': 'david@themainbranch.com',
  //   'emailVerified': true
  // }
  
  // async function callTextMagic() {
  //   // const DID = await magic.auth.loginWithSMS({
  //     // phoneNumber: '+14252137350',
  //   // });
  // }

  // async function signMagicWalletForEthPass() {

  //   const signatureMessage = `Sign this message to generate a test pass with ethpass.xyz`;

  //   const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
  //   const signer = provider.getSigner();
  //   const signature = await signer.signMessage(signatureMessage);
  //   // if successful, call /api/ethpass/create to create the pass
  //   console.log(signature);

  //   const payload = {
  //     signature,
  //     signatureMessage,
  //     platform: 'apple',
  //     chain: {
  //       name: 'evm',
  //       network: 137,
  //     },
  //     nft: {
  //       contractAddress: '0x8c21033Bc82b07256836362e1fC8d03241C645E4',
  //       tokenId: '2',
  //     },
  //     //image: 'https://ipfs.io/ipfs/QmYyXcVMgny4BJYiaUyTU46HX7seFNgnvte2eoL2fN2PXf',
  //   };

  //   const response = await fetch('https://api.ethpass.xyz/api/v0/passes', {
  //     method: 'POST',
  //     body: JSON.stringify(payload),
  //     headers: new Headers({
  //       'content-type': 'application/json',
  //       'X-API-KEY': 'sk_live_gkStREwwyw86ZRo0yJ56kqNpHMF2aI1N',
  //     }),
  //   });

  //   console.log(response);
  // }

export default Home