import React from 'react'

import css from './styles.module.scss';

const IconLocation = props => {
  const {
    children,
    icon,
    cl = ''
  } = props

  let cls = css['cl']

  console.log(cls)

  return (
    <div className={`position-relative ${css.iconLoc} ${css[cl]}`}>
      <div className={`align-items-center d-flex justify-content-center ${css.icon}`}>
        {icon}
      </div>

      {children}
    </div>
  )
}

export default IconLocation