import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import css from '../styles.module.scss'

import IconCheck from '../../../components/Icons/Check';

const FormBasic = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit')
  }

  return (
    <Form onSubmit={handleSubmit}>

      <h3>Basic Information</h3>

      <Form.Group className={css.item} controlId='email'>
        <Form.Label>Current Email</Form.Label>
        <Form.Control type='email' placeholder='Email@gmail.com' required />
      </Form.Group>

      <hr />

      <Form.Group className={css.item} controlId='fname'>
        <Form.Label>First name</Form.Label>
        <Form.Control type='text' placeholder='Olivia' required />
      </Form.Group>

      <Form.Group className={css.item} controlId='lname'>
        <Form.Label>Last name</Form.Label>
        <Form.Control type='text' placeholder='Rhye' required />
      </Form.Group>

      <ButtonGroup className={css.btnActions}>
        <Button variant='secondary' onClick={() => console.log('cancel click')}>Cancel</Button>
        
        <Button type='submit' variant='primary'>
          <IconCheck cl='me-3' />
          Save Changes
        </Button>
      </ButtonGroup>

      <hr />

    </Form>
  )
}

export default FormBasic