import React from 'react'
import IconLogo from '../Icons/Logo'

import css from './styles.module.scss'

const Header = props => {

  const {
    showMenu = false,
    setShowMenu = () => {},
  } = props

  return (
    <header id={css.header}>
      <div className={css.inner}>
        <a href='./'>
          <IconLogo />
        </a>

        <div className={css.mobTriggerContain}>
          <button id='mobTrigger' className={`${css.menu} ${showMenu ? css.open : ''}`} onClick={() => setShowMenu(!showMenu)} aria-label='Main Menu'>
            <span />
            <span />
            <span />
            <span />
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header