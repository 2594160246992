import React from 'react'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'

import { socialData } from './data'
import IconApple from '../Icons/Apple'
import IconFacebook from '../Icons/Facebook'
import IconGoogle from '../Icons/Google'
import IconTwitter from '../Icons/Twitter'

const SocialSignins = (props) => {

  const getSocialIcon = platform => {
    return {
      'Google': <IconGoogle cl='me-3' />,
      'Facebook': <IconFacebook cl='me-3' />,
      'Apple': <IconApple cl='me-3' />,
      'Twitter': <IconTwitter cl='me-3' />
    }[platform]
  }

  const login = async (e, item) => {
    e.preventDefault();

    console.log(props);
    if (item.platform !== "Google") {
      alert("This one isn't here yet.");
      return;
    } else {
      props?.loginSocial(item.platform);
    }
  }

  const socialSigninEls = socialData.map(item => (
    <Button key={item.platform} variant='signup' className='rounded-pill' onClick={(e) => { login(e, item); }}>
      {getSocialIcon(item.platform)}
      Sign up with {item.platform}
    </Button>
  ))
  
  return (
    <div className={css.signupBtns}>
      {socialSigninEls}
    </div>
  )
}

export default SocialSignins