import React from 'react'
import TicketPow from '../../../components/TicketPow/TicketPow'
import css from './styles.module.scss'

const CollectionClaimed = (props) => {
  
  const data = props?.nftCollection;
  const powsEls = data.map(li => {
    return (
      <li key={li.n_id} className={`${css.item}`}>
        <TicketPow data={li} />
      </li>
    )
  })

  return (
    <>
      <ul className={`align-items-start d-flex list-unstyled mb-0 ${css.claimedPows}`}>
        {powsEls}
      </ul>
    </>
  )
}

export default CollectionClaimed
