import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import IconError from '../../../components/Icons/Error'
import IconArrowRight from '../../../components/Icons/ArrowRight'

const EmailForm = props => {
  const {
    setShowContent = () => '',
  } = props

  //console.log("EmailForm: ", props);
  //console.log("magic form: ", props?.magic);

  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [contact, setContact] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [first, setFirst] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowContent('step2')
  }

  const validate = () => {
    if (!email) {
      setError('Email is required');
      setValidEmail(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setError('Invalid email address');
      setValidEmail(false);
    } else {
      setError(false);
      setValidEmail(true);
    }
  }

  useEffect(() => {
    if (!first) {
      validate();
      // navigator.geolocation.getCurrentPosition(function(position) {
      //   console.log("Latitude is :", position.coords.latitude);
      //   console.log("Longitude is :", position.coords.longitude);

      // });
    } else {
      setFirst(false);
    }
  }, [email])

  


  return (
    <Form onSubmit={handleSubmit} className={`d-flex flex-column`}>
      <Form.Group className={`position-relative form-group ${error ? 'error' : ''}`} controlId='email' required>
        {error && <IconError />}

        <Form.Label className='d-none form-label'>Enter your email</Form.Label>
        <Form.Control type='email' placeholder='Enter your email' value={email} onInput={e => setEmail(e.target.value)} />
      </Form.Group>

      <Form.Group className='form-check' controlId='agree' required>
        <Form.Control type='checkbox' className='form-check-input' />
        <input type="checkbox" checked={privacy} onClick={()=> setPrivacy(!privacy)} onChange={()=> setPrivacy(!privacy)}></input>
        <span className='checkmark' checked={true} onClick={()=> setPrivacy(!privacy)}></span>
        
        <Form.Label className='form-check-label' onClick={()=> setPrivacy(!privacy)}>By checking this box you agree to the <a href='#' onClick={() => console.log('click')}>Terms of Use</a> & <a href='#' onClick={() => console.log('click')}>Privacy Policy</a></Form.Label>
      </Form.Group>

      <Form.Group className='form-check' controlId='contact' required>
        <Form.Control type='checkbox' className='form-check-input' />
        <input type="checkbox" checked={contact} onClick={()=> setContact(!contact)} onChange={()=> setContact(!contact)}></input>
        <span className='checkmark' onClick={()=> setContact(!contact)}></span>
        <Form.Label className='form-check-label' onClick={()=> setContact(!contact)}>I give my consent for IMAX to contact me in order to send information about proudcts and services that I might be interested in.</Form.Label>
      </Form.Group>
      
      <Button variant='primary' className={`align-items-center d-flex justify-content-start w-100 btn-circ`} disabled={!privacy || !validEmail} onClick={ () => {props.loginEmail(email)}}>
        <div className={`align-items-center d-flex justify-content-center`}><IconArrowRight /></div>
        Continue
      </Button>
    </Form>
  )
}

export default EmailForm