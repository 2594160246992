import moment from 'moment'

export default class DateHelper {

    static convertUTCtoLocalMoment(dateUTC, offset = -420) {
        return new moment.utc(dateUTC).utcOffset(offset)
    }

    static utc() {
        return new moment.utc();
    }

    static fromSqlDate = function(date) {
        if (!date) {
          return date;
        }
      
        var dateWithoutTime = moment.utc(date).format("YYYY-MM-DD");
        return dateWithoutTime;
      };
      
      static fromSqlDateWithTime = function(date) {
        if (!date) {
          return date;
        }
      
        var dateWithoutTime = moment.utc(date).format("YYYY-MM-DD HH:mm:SS");
        return dateWithoutTime;
      };
      
      static convertIsoToSql(date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      };
};