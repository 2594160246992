import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import css from '../styles.module.scss'

import PageTitle from '../../../components/PageTitle/PageTitle'
import IconLocation from '../../../components/IconLocation/IconLocation'
import IconX from '../../../components/Icons/X'
import IconRefresh from '../../../components/Icons/Refresh'
import { useHistory } from 'react-router-dom'

const LocationNone = () => {
  const history = useHistory();

  return (
    <>
      <PageTitle>
        <h1>Hmm. We can't find you...</h1>
        <p>In order to award you the proper POW, we need to use your device's location.</p>
      </PageTitle>

      <IconLocation cl='error' icon={<IconX />}>
        <p>We're Unable to Determine Your Current Location</p>
      </IconLocation>

      <ButtonGroup className={`d-flex mx-auto ${css.btnActions}`}>
        <Button variant='primary' 
          className={`rounded-pill`}
          onClick={() => history.push('/create-account')} >
          <IconRefresh cl={`me-3`} />
          Try Again
        </Button>
      </ButtonGroup>

      <p className='mt-3 mx-auto text-center'>
        Not Sure Hot to Fix it?<br />
        <a href='#' onClick={() => console.log('click')}>How to Enable Location Services</a>
      </p>
    </>
  )
}

export default LocationNone