import React from 'react'
import { NavLink } from 'react-router-dom'

import css from './styles.module.scss'

const Navigation = props => {
  const {
    showMenu = false,
    setShowMenu = () => {},
    isLoggedIn = false,
  } = props

  async function signOut() {
    props?.logout();
  }

  return (
    <div id={css.navigation} className={`${showMenu ? css.open : ''}`}>
      <ul>
        <li>
          <NavLink
            to='./'
            onClick={() => setShowMenu(false) }
            activeClassName={ css.active}
          >Home</NavLink>
        </li>
        { isLoggedIn && <li>
          <NavLink
            to='./collection'
            onClick={() => setShowMenu(false) }            
            activeClassName={css.active}
          >My POWs</NavLink>
        </li> 
        }
        <li>
          <NavLink
            to='./faqs'
            onClick={() => setShowMenu(false) }
            activeClassName={css.active}
          >FAQs</NavLink>
        </li>
        { isLoggedIn && <li>
          <NavLink
            to='./account-settings'
            onClick={() => setShowMenu(false) }
            activeClassName={css.active}
          >Account Settings</NavLink>
        </li>
        }
        { isLoggedIn && <li className={css.logout}>
          <button
            to='./account-settings'
            onClick={() => signOut() }
            className={`btn btn-danger`}
          >Sign Out</button>
        </li>
      }
      </ul>
    </div>
  )
}

export default Navigation