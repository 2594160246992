import React, { useState, useEffect } from 'react'
import { prominent } from 'color.js'

import css from './styles.module.scss'

const PosterArtCircle = props => {

  const {
    image = '',
    height = '',
    width = '',
    top = 0,
    right = 'auto',
    bottom = 'auto',
    left = 'auto',
    loc = 'left',
  } = props

  const [colors, setColors] = useState({})

  const shHeight = height / 1.5
  const shWidth = width / 1.5

  useEffect(() => {
    let isMounted = true;
    prominent( image, { amount: 20, format: 'hex' })
      .then((res) => {
        if (isMounted) setColors(res)
      })

      return () => { isMounted = false };
  }, [])
  
  const cssTokenHolder = {
    height: height,
    inset: `${top} ${right} ${bottom} ${left}`,
    width: width,
  }

  const cssToken = {
    backgroundImage: `url(${image})`,
    height: height,
    inset: `0 auto auto 0`,
    width: width,
  }

  const cssShadow = {
    backgroundColor: `${colors[0]}`,
    height: shHeight,
    width: shWidth,
  }

  return (
    <div className={`${css.tokenHolder} ${loc === 'left' ? css.toRight : css.toLeft}`} style={cssTokenHolder}>
      <div className={css.token} style={cssToken} />
      
      <div className={`${css.shadow}`} style={cssShadow} />

      <svg className={css.subShadow} width='47' height='57' viewBox='0 0 47 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M27.1785 4.42514C30.7254 0.388106 36.6854 -0.461336 41.2189 2.42405V2.42405C45.767 5.31877 47.5156 11.1037 45.3323 16.033L31.5292 47.1975C27.9564 55.2639 18.5628 58.9596 10.4515 55.4898V55.4898C0.367757 51.1763 -2.55718 38.27 4.68176 30.0307L27.1785 4.42514Z' fill={colors[19]} />
      </svg>
    </div>
  )
}

export default PosterArtCircle