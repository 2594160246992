import React from 'react'

import css from './styles.module.scss'

import postmates from '../../img/postmates.png'
import streakCircle from '../../img/streakCircle.png'
import streakTri from '../../img/streakTri.png'
import streakHex from '../../img/streakHex.png'
import streakCreedIII from '../../img/streakCreedIII.png'
import IconArrowRight from '../../components/Icons/ArrowRight'

const Streaks = props => {

  const {
    onClaimReward,
  } = props

  return (
    <>
      <hr className={css.hr} />

      <div id={css.streaksBlock}>
        <h2>Streaks</h2>

        <div className={css.claim}>
          <div className={css.presentedStatus}>
            <div className={css.presented}>
              <p className={css.title}>Midnight Master</p>
              <p className={css.by}>
                Presented By
                <img src={postmates} alt='Postmates' />
              </p>
            </div>

            <p className={css.status}>Completed</p>
          </div>

          <div className={css.icons}>
            <div className={css.icon}>
              <img src={streakCircle} alt='' />
            </div>
            <div className={css.icon}>
              <img src={streakTri} alt='' />
            </div>
            <div className={css.icon}>
              <img src={streakHex} alt='' />
            </div>
          </div>

          <button type='button' className={`btn btn-white`} onClick={() => console.log('click')}>
            Claim Reward
            <IconArrowRight cl='ms-3' />
          </button>
        </div>

        <div className={css.week}>
          <div className={css.streakCompleted}>
            <p>5 Week Streak</p>

            <p className={css.completed}>2 of 5 complete</p>
          </div>

          <div className={css.icons}>
            <div className={css.icon}>
              <img src={streakHex} alt='' />
            </div>
            <div className={css.icon}>
              <img src={streakCreedIII} alt='' />
            </div>
            <div className={css.icon}></div>
            <div className={css.icon}></div>
            <div className={css.icon}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Streaks