import React from 'react'

import css from './styles.module.scss'
import imaxLogo from '../../img/imax-logo.png'
import ImaxImageProvider from '../../scripts/ImaxImageProvider';

const TicketItem = props => {
  const {
    cl = '',
    label = '',
    entry = '',
  } = props

  return (
    <div className={`${cl} ${css.item}`}>
      <p className={`${css.label}`}>{label}</p>
      <p className={`${css.entry}`}>{entry}</p>
    </div>
  )
}

const Ticket = props => {

  const {
    abbr = '',
    size = 'sm',
    title = '',
    theater = '',
    showtime = '',
    edition = '',
    available = '',
    rotate = '0',
    date = '',
  } = props

  const img = ImaxImageProvider.getImageForNFT(props?.abbr);

  const cssSmall = { transform: `translateX(-50%) rotate(${rotate}deg)` }

  return (
    <>
      {size === 'sm'
        ?
          <div className={`${css.ticketBlock} ${css.sm}`} style={cssSmall}>
            <img src={img} alt='' className={`${css.ticket}`} />

            <div className={css.content}>
              <div className={`${css.title}`}>{abbr}</div>

              <div className={css.itemHolder}>
                <TicketItem cl='pe-2 ps-3' label='Theater' entry={theater} />
                <TicketItem cl='px-2' label='Showtime' entry={showtime} />
                <TicketItem cl='ps-2' label='Edition' entry={`${edition}/${available}`} />
              </div>
            </div>
          </div>
        :
          <div className={`${css.ticketBlock} ${css.lg}`}>
            <img src={img} alt='' className={`${css.ticket}`} />

            <div className={css.content}>
              {/* <img src={pin} alt='' className={css.pin} /> */}
              <img src={imaxLogo} alt='' className={css.imaxLogo} />

              {/* <div className={`${css.title}`}>{abbr}</div> */}

              <div className={css.itemHolder}>
                <TicketItem cl='w-100' label='Movie' entry={title} />
                <TicketItem label='Date' entry={date} />
                <TicketItem label='Showtime' entry={showtime} />
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Ticket