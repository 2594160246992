import React from 'react'

import css from './styles.module.scss'

const InfoPill = props => {

  const {
    title = '',
  } = props

  return (
    <div className={`d-flex ${css.infoPill}`}>{title}</div>
  )
}

export default InfoPill