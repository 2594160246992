import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import PowBlock from '../../components/PowBlock/PowBlock'
import Ticket from '../../components/Ticket/Ticket'
import InfoPill from '../../components/InfoPill/InfoPill'
import LoaderVerify from '../../components/LoaderVerify/LoaderVerify'
import IconClaim from '../../components/Icons/Claim'
import IconCheck from '../../components/Icons/Check'

const Claiming = () => {

  const [status, setStatus] = useState('claiming')

  const getTitle = status => {
    return {
      'claiming': 'Verifying Location',
      'failure': 'Verifying Location Failed'
    }[status]
  }

  return (
    <main id='main'>

      <section id={css.claiming} className={`container-fluid px-0`}>
        <InfoPill title={getTitle(status)} />

        <LoaderVerify width='75' cl={status === 'failure' ? 'fail' : ''} />

        <PageTitle>
          {status === 'failure'
            ?
              <>
                <h1>Let's try that again...</h1>
                <p>We couldn't deliver your POW. You can restart by clicking or tapping CLAIM POW below or set yourself a reminder to return another time.</p>
              </>
            :
              <>
                <h1>Claiming your POW...</h1>
                <p>Hold on to your butts.</p>
              </>
          }
        </PageTitle>

        {status === 'failure'
          ?
            <>
              <div className={css.btnActions}>
                <Button
                  variant='primary'
                  className={`w-100 btn-circ`}
                  onClick={() => console.log('claim click')}
                >
                  <div className={css.iconHolder}><IconClaim /></div>
                  Claim POW
                </Button>
                
                <a href='#' onClick={() => console.log('click')} className={css.remind}>Remind Me to Claim Later</a>
                <a href='#' onClick={() => console.log('click')} className={css.set}><IconCheck cl='me-3' />Reminder Set</a>
              </div>
              
              <p className={`text-center`}>We'll email you later to remind you.  If it doesn't make it to your inbox you can return to <a href='https://x.imax.com' target='_blank'>x.imax.com</a> at any time to sign in and finish the claim process.</p>
            </>
          :
            <>
              <div className={css.powHolder}>
                <PowBlock>
                  <Ticket
                    size='sm'
                    abbr='AVTR'
                    theater='Regal Irvine Spectrum & IMAX'
                    showtime='11 p.m.'
                    edition='12'
                    available='2000'
                    rotate='0' />
                </PowBlock>
              </div>

              <p className={`text-center`}>Do Not Close This Page Until It Completes.</p>
            </>
        }
      </section>
    </main>
  )
}

export default Claiming