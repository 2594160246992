import React, { useEffect } from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom"

import css from '../styles.module.scss'

import IconLocation from '../../../components/IconLocation/IconLocation'
import IconLocMarker from '../../../components/Icons/LocMarker'
import PageTitle from '../../../components/PageTitle/PageTitle'
import IconRefresh from '../../../components/Icons/Refresh'
import IconCheck from '../../../components/Icons/Check'

const LocationSuccess = (props) => {
  console.log(props)
  console.log(props.detectedShow);
  const history = useHistory();
  const t_name = props?.detectedShow?.theater_name ?? ''
  const t_address = props?.detectedShow?.address_summary ?? ''
  const t_address2 = (props?.detectedShow?.city ?? '') + ", " + (props?.detectedShow?.state ?? '')

  async function setContent() {
      console.log("Setting content");
      document.title = 'IMAX - Confirm Information';
  }

  useEffect (async () => {
    await setContent()
  }, []);

  //const t_name = t_array && t_array.length > 0 ? t_array[0].theater_name : 'empty'
  return (
    <>
      <PageTitle>
        <h1>Are You Here?</h1>
        <p>Confirm Based on your location and current showtimes.</p>
      </PageTitle>

      <IconLocation icon={<IconLocMarker />}>
        <h3 className={`mb-2`}>{t_name}</h3>
        <p>{t_address}<br />
           {t_address2}
        </p>
      </IconLocation>

      <ButtonGroup className={`d-flex w-100 ${css.btnActions}`}>
        <Button variant='secondary' onClick={() => history.push("/create-account") }>
          <IconRefresh cl={`me-3`} />
          Try Again
        </Button>

        <Button variant='primary' onClick={() => { history.push("./confirmation", this?.props.location.state) }}>
          <IconCheck cl='me-3' />
          That's Correct
        </Button>
      </ButtonGroup>

      <p className='mt-3 mx-auto text-center'>
        Need Help?<br />
        <a href='#' onClick={() => console.log('click')}>Contact Support</a>
      </p>
    </>
  )
}

export default LocationSuccess