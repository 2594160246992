import React from 'react'

const IconArrowRight = props => {

  const { cl = '' } = props

  return (
    <svg className={cl} width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.833252 6H14.1666M14.1666 6L9.16659 1M14.1666 6L9.16659 11' stroke='#1D2939' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}

export default IconArrowRight