
import React, { useState, useEffect } from 'react';

import css from './styles.module.scss';

import CreateAccountEmail from './Email/Email';
import Steps from '../../components/Steps/Steps';
import CreateAccountSuccess from './Success/Success';
import Loader from '../../components/Loader/Loader';

const CreateAccountSwitch = (props) => {
  
  const [loading, setLoading] = useState(true)
  const [headerContent, setHeaderContent] = useState("step1")
  const [showContent, setShowContent] = useState(<CreateAccountEmail setShowContent={setShowContent} loginEmail={loginEmail} loginSocial={loginSocial}/>)
  
  async function loginSocial(provider) {
    const baseUrl = window.location.origin; 
    // this should capture the right baseUrl (http://localhost:3000 or https://imax.azure....)
    const redirectUrl = 'https://redirectmeto.com/' + baseUrl + "/oauth";
    console.log("There - ", redirectUrl);
    //debugger;
    console.log(props?.magic);
    console.log(provider.toLowerCase());
    await props?.magic?.oauth.loginWithRedirect({
      provider: provider.toLowerCase() /* 'google', 'facebook', 'apple', or 'github' */,
      redirectURI: redirectUrl,
    });
  }
  
  async function loginEmail (email) {
    try {
      console.log(email);
      const didToken = await props?.magic?.auth.loginWithMagicLink({email});
      console.log(didToken);
      const res = await fetch(`/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
        },
      });
    
      if (res.status === 200) {
        // Set the UserContext to the now logged in user
        const userMetadata = await props?.magic?.user.getMetadata();
        console.log(userMetadata);
        props?.setUser(props?.magic?.user)
        
        return createAccountSuccess();
      } else {
        //props?.setIsLoggedIn(false);
        console.log(res);
      }
      
      console.log('Done')
    } catch (err) {
      props?.setIsLoggedIn(false);
      console.error(err);
    }
  }

  function createAccountSuccess() {
      setShowContent(<CreateAccountSuccess 
                        type='1' 
                        setDetectedShow={props.setDetectedShow} 
                        setLoading={setLoading}/>);
      props?.setIsLoggedIn(true);
  }

  async function setContent() {
    try {
      console.log("Setting content");
      document.title = 'IMAX - Login or Create Account';
      
      //setLoading(true);
      console.log(props);
      if(!props?.magic) {
        setLoading(true);
      }
      else if (await props?.magic?.user?.isLoggedIn()) {
        createAccountSuccess();
      } else {
        console.log("Not logged in")
        setLoading(false);
      }
    } catch(err) {
      console.error("Error getting user metadata", err);
      setLoading(false);
    }
    //console.log(props);
  }

  useEffect (() => {
    async function _setContent() {
      await setContent()
    }
    _setContent();
  }, []);

  useEffect (() => {
    console.log("Magic set")
    console.log(props);
    async function _setContent() { 
      await setContent()
    }
    _setContent();
  }, [props.magic]);

  return (
    <main>

      { loading && <Loader /> }

      <section id={css.createAccount} className={`container-fluid`}>
        <Steps showContent={headerContent} num={2} />
        {showContent}
      </section>

    </main>
  )
}

export default CreateAccountSwitch;
