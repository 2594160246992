import React from 'react'

import css from './styles.module.scss'

import LocationSuccess from './Success/Success'
import LocationWrong from './Wrong/Wrong'
import LocationNone from './NoLocation/NoLocation'

const LocationSwitch = (props) => {
  console.log(props)

  let showFound = props?.detectedShow ? true : false;
  let locationFound = props?.detectedShow?.lat > 0 ? true : false;

  const content = showFound && locationFound
    ? <LocationSuccess detectedShow={props.detectedShow}/>
    : locationFound // decide which error based on if we found a location, or not
      ? <LocationWrong /> 
      : <LocationNone />

  return (
    <main>

      <section id={css.location} className={`container-fluid px-0`}>
        
        {content}
      </section>

    </main>
  )
}

export default LocationSwitch