import React from 'react'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'

import loyaltyCard from '../../../img/collection/loyaltycard.png'
import appleWallet from '../../../img/collection/applewallet.png'

const LoyaltyCard = (props) => {

  // function onAppleWalletClick(e) {
  //   e.preventDefault();
  //   console.log("loading to wallet");
  // }

  return (
    <div className={css.loyaltyCardWrapper}>
      <img src={loyaltyCard} className={css.loyaltyCard} alt="loyalty card" />
      <Button 
          className={css.appleWallet} 
          variant='link' 
          //onClick={(e) => onAppleWalletClick(e) }
          href="/pass/ximax.pkpass" >
          
            <img src={appleWallet} alt="add to apple wallet " />
      </Button>
    </div>
  )
}

export default LoyaltyCard