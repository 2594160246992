import React, { useState, useEffect } from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'
import { useHistory } from "react-router-dom"

import PageTitle from '../../components/PageTitle/PageTitle'
import IconRefresh from '../../components/Icons/Refresh'
import IconArrowTopRight from '../../components/Icons/ArrowTopRight'
import IconCheck from '../../components/Icons/Check'

import moment from 'moment';
import DateHelper from '../../scripts/DateHelper'

const ConfirmItem = props => {
  const {
    title = '',
    content = '',
  } = props

  return (
    <div className={css.item}>
      <h5>{title}</h5>
      <h3 className={`mb-0`}>{content}</h3>
    </div>
  )
}

const Confirmation = (props) => {
  console.log(props)
  const history = useHistory();

  const [error, isError] = useState(false)
  const [localDate, setLocalDate] = useState(DateHelper.utc());

  const movieInfo = props?.detectedShow ? props?.detectedShow : null;
  
  const confirm = async () => {
    document.title = 'IMAX - Claim Your POW';
    history.push('./claim-pow', this?.props.location.state)
  }

  function setContent() {
    if (props?.detectedShow) {
      console.log("confirm: ", props?.detectedShow);
      const ldate = DateHelper.convertUTCtoLocalMoment(props?.detectedShow?.show_time)
      console.log("ldate : ", ldate);
      setLocalDate(ldate);
    }
  }

  useEffect(async () => {
    setContent();
  }, [props]);


  
  if (!movieInfo) { 
    console.log("Props: ", props);
    return ( <> 
              <div>No data</div>
                </> 
            )};

  return (
    <main>

      <section id={css.confirmation} className={`container-fluid px-0`}>
        <PageTitle>
          <h1>
            {!error
              ? `Make sure this all looks right.`
              : `That didn't work out.`
            }
          </h1>
          <p>
            {!error
              ? `Confirm your location, movie and showtime below to ensure you get the right POW.`
              : `There are no POW-enabled screenings available for you to claim right now.`
            }
          </p>
        </PageTitle>

        <div className={css.confirmBlock}>
          {error && <div className={`${css.blur}`} />}

          <ConfirmItem title='Movie' content={props?.detectedShow?.movie_name} />

          <ConfirmItem title='Theatre Location' content={props?.detectedShow?.theater_name} />

          <div className={css.item_two}>
            <ConfirmItem title='Date' content={localDate?.format('MMM Do YYYY')} />

            <ConfirmItem title='Showtime' content={ localDate?.format('h:mm a') } />
          </div>
        </div>

        <ButtonGroup className={css.btnActions}>
          <Button variant='secondary' className={`rounded-pill`}>
            <IconRefresh cl={`me-3`} />
            Try Again
          </Button>

          {!error
            ? 
              <Button variant='primary' className={`rounded-pill`} onClick={() => {confirm()}}>
                <IconCheck cl='me-3' />
                Confirm
              </Button>
            :
              <Button variant='primary' className={`rounded-pill`}>
                <IconArrowTopRight cl='me-3' />
                Learn More
              </Button>
          }
        </ButtonGroup>

        <p className='mt-3 mx-auto text-center'>
          Need Help?<br />
          <a href='#' onClick={() => console.log('click')}>Contact Support</a>
        </p>
      </section>

    </main>
  )
}

export default Confirmation