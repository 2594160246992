import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import IconRefresh from '../../components/Icons/Refresh'
import IconArrowTopRight from '../../components/Icons/ArrowTopRight'

const Error = () => {
  return (
    <main>

      <section id={css.error} className={`container-fluid`}>
        <PageTitle>
          <h1>Hmm. Something didn't work.</h1>
          <p>Looks like we got a little lost along the way. You can try to start over or get in touch with us.</p>
        </PageTitle>

        <ButtonGroup className={css.btnActions}>
          <Button variant='secondary' classN onClick={() => console.log('click')}>
            <IconRefresh cl='me-3' />
            Start Over
          </Button>

          <Button variant='primary' onClick={() => console.log('click')}>
            <IconArrowTopRight cl='me-3' />
            Contact Support
          </Button>
        </ButtonGroup>
      </section>

    </main>
  )
}

export default Error