import React from 'react'

import css from './styles.module.scss'

const Loader = () => {
  return (
    <div id={css.loading}>
      <div className={css.contain}>
        {[...Array(16)].map((e,i) => <div key={i} className={css.circle}><div className={css.dot}></div></div>)}
      </div>
      Loading...
    </div>
  )
}

export default Loader