import React from 'react'

const IconLogo = () => (
  // <svg width='103' height='20' viewBox='0 0 103 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //   <path fillRule='evenodd' clipRule='evenodd' d='M6.31211 19.698H0V0.060791H6.31211V19.698ZM16.3656 5.49458L16.358 19.6987H10.7344L10.7496 0.106998L20.855 0.0759634L26.9279 11.8029L32.9432 0.060791H43.0957V19.6987H37.461V5.49458H36.3135L29.254 19.6829H24.5483L17.493 5.49458H16.3656ZM54.3476 0.060791L44.209 19.6994H50.9523L52.7162 16.3594H65.3154L67.0342 19.6994H73.7935L63.6494 0.060791H54.3476ZM59.3209 4.52493H58.7003L54.862 11.9629H63.1162L59.3209 4.52493ZM75.5636 0.060791H83.3976L88.6794 6.44148L94.1179 0.060791H101.813L93.9508 9.26838L103.339 19.6987H95.0151L88.7328 12.6029L82.3798 19.6987H74.0293L83.4711 9.27734L75.5636 0.060791Z' fill='#1D2939'/>
  // </svg>
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5648_120566)">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L13 7.63611L3.86818 13L0 10.7279L0 0ZM0 15.638L13 8V18.3619L0 26L0 15.638ZM22.7518 12.7768L23.1319 13L27 10.7279V0L14 7.63611L22.7518 12.7769V12.7768ZM27 15.638V26L14.1419 18.4455L14 18.3619V8L27 15.638Z" fill="#101828"/>
    </g>
    <defs>
    <clipPath id="clip0_5648_120566">
    <rect width="27" height="26" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)

export default IconLogo