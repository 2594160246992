import React from 'react'

import css from './styles.module.scss'

const Separator = props => {
  const {
    text = '',
  } = props

  console.log(text)

  return (
    <div className={css.sep}>
      {text && <span>{text}</span>}
    </div>
  )
}

export default Separator