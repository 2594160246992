import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import PowBlock from '../../components/PowBlock/PowBlock'
import Ticket from '../../components/Ticket/Ticket'
import IconShare from '../../components/Icons/Share'
import IconArrowRight from '../../components/Icons/ArrowRight'

const ClaimSuccess = () => {
  return (
    <main>

      <section id={css.claimSuccess} className={`container-fluid px-0`}>
        <PageTitle>
          <h1>Congratulations</h1>
          <p>You earned it!</p>
        </PageTitle>

        <PowBlock>
          <Ticket
            abbr='AVTR'
            theater='Regal Irvine Spectrum & IMAX'
            showtime='11 p.m.'
            edition='12'
            available='2000'
            rotate='-10.99' />
        </PowBlock>

        <ButtonGroup className={css.btnActions}>
          <Button variant='secondary' className={`rounded-pill`}>
            <IconShare cl='me-3' />
            Share
          </Button>

          <Button variant='primary' className={`rounded-pill`} onClick={() => {window.location="./collection"}}>
            View Collection
            <IconArrowRight cl='ms-3' />
          </Button>
        </ButtonGroup>
      </section>

    </main>
  )
}

export default ClaimSuccess