import React from 'react'

const IconArrowTopRight = props => {

  const {
    cl = ''
  } = props

  return (
    <svg className={`${cl}`} width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.5 11L11.5 1M11.5 1H4.83333M11.5 1V7.66667' stroke='#1D2939' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}

export default IconArrowTopRight