import React, { createContext, useReducer } from 'react';

export const AppStateContext = createContext();
export const AppDispatchContext = createContext();

const initState = {
    user: {}
};

export const appReducer = (state, action) => {
    switch (action.section) {
        case 'user':
            return {...state, user: action.payload}
        default:
            throw new Error(`${action.section} passed to reducer is not defined`);
    }
}

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initState);
    return (
        <AppDispatchContext.Provider value={dispatch}>
            <AppStateContext.Provider value={state}>
                { children }
            </AppStateContext.Provider>
        </AppDispatchContext.Provider>
    )
}

export default AppProvider