import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import css from '../styles.module.scss'

import CheckList from '../../../components/CheckList/CheckList'
import IconCheck from '../../../components/Icons/Check'

const FormPassword = () => {

  const checks = {
    title: 'Your password must include:',
    items: [
      { id: 0, checked: 1, copy: 'Minimum 8 characters (12+ recommended)' },
      { id: 1, checked: 0, copy: 'One uppercase letter.' },
      { id: 2, checked: 0, copy: 'One lowercase letter.' },
      { id: 3, checked: 0, copy: 'One number.' },
      { id: 4, checked: 0, copy: `One of the following special characters (!”#$%&'()*+,-./:;<=>?` },
    ]
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('submit')
  }

  return (
    <Form onSubmit={handleSubmit}>

      <h3>Update Your Password</h3>

      <Form.Group className={css.item} controlId='pass'>
        <Form.Label>Current Password</Form.Label>
        <Form.Control type='password' placeholder='Enter Your Current Password' required />
      </Form.Group>

      <Form.Group className={css.item} controlId='newPass'>
        <Form.Label>New Password</Form.Label>
        <Form.Control type='password' placeholder='Enter Your New Password' required />
      </Form.Group>

      <Form.Group className={css.item} controlId='confirmPass'>
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control type='password' placeholder='Re-enter Your New Password' required />
      </Form.Group>

      <CheckList checks={checks} />

      <ButtonGroup className={css.btnActions}>
        <Button variant='secondary' onClick={() => console.log('cancel click')}>Cancel</Button>

        <Button type='submit' variant='primary'>
          <IconCheck cl='me-3' />
          Update Password
        </Button>
      </ButtonGroup>

    </Form>    
  )
}

export default FormPassword