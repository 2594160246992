import React from 'react';


export default class MagicUtility {
    static MAGIC_PUBLICKEY = "pk_live_AE0FC1458819A7EC";

    static magic = null;

    static async setupMagic() {
        if(!this.magic) {
            this.magic = 3;
        }
        return this.magic;
    }
}