import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import css from './styles.module.scss'

import PageTitle from '../../components/PageTitle/PageTitle'
import NavPills from '../../components/NavPills/NavPills'
import CollectionUnclaimed from './Unclaimed/Unclaimed'
import CollectionClaimed from './Claimed/Claimed'
import CollectionUpcoming from './Upcoming/Upcoming'
import ImaxClient from '../../imaxClient';

import Streaks from '../../components/Streaks/Streaks'
import LoyaltyCard from './LoyaltyCard/LoyaltyCard';

const PowCollectionSwitch = (props) => {
  const {
    setDetectedShow = () => {}
  } = props

  const history = useHistory();
  const [showContent, setShowContent] = useState('claimed')
  const [collection, setCollection] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);

  useEffect (() => {
    async function loadCollection() {
      try {
        let my_collection = await ImaxClient.getMyCollection();
  
        if (my_collection) {
          console.log("collection: ", my_collection);
          let my_filtered = my_collection.filter(nft => nft.token_id !== '');
          console.log("filtered: ", my_filtered);
          setCollection(my_filtered);
  
          let my_unclaimed = my_collection.filter(nft => nft.token_id === '');
          console.log("unclaimed: ", my_unclaimed);
          setUnclaimed(my_unclaimed);
        }
      } catch (err) {
        console.error(err);
      }
    }

    document.title = 'IMAX - My Collection';
    loadCollection();
  }, []);

  const showUnclaimedStatus = unclaimed?.length > 0 ? true : false;

  const content = showContent === 'claimed'
  ? <CollectionClaimed nftCollection={collection} />
  : showContent === 'unclaimed'
    ? <CollectionUnclaimed nftCollection={unclaimed} setDetectedShow={props?.setDetectedShow} />
      : <LoyaltyCard />

  return (
    <main>

      <section id={css.collection} className='container-fluid'>
        <PageTitle>
          <h1>My Pows</h1>
        </PageTitle>

        <NavPills showContent={showContent} setShowContent={setShowContent} showBadge={showUnclaimedStatus} />

        {content}

        <Streaks onClaimReward={props?.onClaimReward} />
      </section>

    </main>
  )
}

export default PowCollectionSwitch
