
export default class LocationUtility {
    static locationOptions = {
        enableHighAccuracy: false,
        timeout: 15000,
        maximumAge: 10000,
    }

    static async getErrorMessage(error) {
        console.error("Geolocation Error - " + error.message);
        LocationUtility.getGeolocationPermission();
        switch (error.code) {
            case 1: //GeoLocationPositionError.PERMISSION_DENIED
              return "Denied - " + error.message + " Make sure your browser is allowed to share location in your settings.";
            //break;

            case 2: //GeoLocationPositionError.PERMISSION_DENIED
              return "Unavailable - " + error.message + " Make sure your browser is allowed to share location in your settings.";
            //break;
      
            case 3: //GeoLocationPositionError.PERMISSION_DENIED
              return "Timeout - " + error.message + " Make sure your browser is allowed to share location in your settings.";
            //break;
      
            default:
              return "Sorry, no position available. Make sure your browser is allowed to share location in your settings." + error.message;
          }
    }

    static async isLocationEnabled() {
        return new Promise((resolve, reject) => {
            if (navigator.permissions) { 
                // Permission API is implemented
                navigator.permissions.query({ name: 'geolocation' })
                    .then(permission =>
                        // is geolocation granted?
                        resolve(permission.state === "granted")
                    ) 
            } else {
                // Permission API was not implemented
                reject(new Error("Permission API is not supported"))
            }
        }
        )
    }
  
    static async getGeolocationPermission() {
        /*let permission = */ navigator.permissions.query({name:'geolocation'})
            .then(function(result) {
                // just printing for now for debugging
                console.log(result.state);
                        
                    // if (result.state === 'granted') {
                    // //to do things here 
                    
                    
                    // } else if (result.state === 'prompt') {
                    // //ask for permission
                    
                    // } else if(result.state === 'denied'){
                    // //keep asking for permission
                    
                    // }
                    // //alert(result.state);
            }
        );
    }


        

  // function testWatch() {
  //   //alert("setting position");
  //   navigator.geolocation.watchPosition(function (position) {
  //     //alert("found position - " + position);

  // }, function (error) {
          
  //               alert("Error");
          

  //     }
  // ); 
  // }

}