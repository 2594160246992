import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import IconError from '../../../components/Icons/Error'
import IconArrowRight from '../../../components/Icons/ArrowRight'

const SignInForm = props => {

  const {
    setShowContent = () => '',
  } = props

  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowContent('step2')
  }

  return (
    <Form onSubmit={handleSubmit} className={`d-flex flex-column w-100`}>
      <Form.Group className={`position-relative form-group ${error ? 'error' : ''}`} controlId='email' required>
        {error && <IconError />}

        <Form.Label className='d-none form-label'>Enter your email</Form.Label>
        <Form.Control type='email' placeholder='Enter your email' value={email} onInput={e => setEmail(e.target.value)} />
      </Form.Group>
      
      <Button variant='primary' className={`align-items-center d-flex justify-content-start w-100 btn-circ`} onClick={() => {props.loginEmail(email)}}>
        <div className={`align-items-center d-flex justify-content-center`}><IconArrowRight /></div>
        Continue
      </Button>
    </Form>
  )
}

export default SignInForm