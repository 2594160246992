import React from 'react'

import css from './styles.module.scss'

const Steps = props => {

  const {
    showContent = '',
    num = '',
  } = props

  const stepEls = [...Array(num)].map((e, i, row) => {
    const rowLen = row.length

    return (
      <li key={i + 1} className={showContent === `step${i + 1}` ? css.active : ''}>
        {rowLen !== i + 1
          ? i + 1
          : showContent === 'step2'
            ? <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 1L6 12L1 7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
            : i + 1
        }
      </li>
    )
  })

  return (
    <ul id={css.steps}>
      {stepEls}
    </ul>
  )
}

export default Steps