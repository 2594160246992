import React, { useEffect, useState } from 'react'

import EmailForm from './EmailForm'
import Separator from '../../../components/Separator/Separator'
import SocialSignins from '../../../components/SocialSignIns/SocialSignins'
import PageTitle from '../../../components/PageTitle/PageTitle'
import SignInForm from './SignInForm'

const CreateAccountEmail = props => {
  console.log('-- Email')

  const {
    setShowContent = () => '',
    loginEmail = (email) => '',
    loginSocial = (provider) => ''
  } = props

  const [isSignUp, setIsSignUp] = useState(true)

  const handleClick = e => {
    e.preventDefault()

    setIsSignUp(!isSignUp)
  }

  const h1 = isSignUp ? `First, let's create your account.` : `Sign-in to claim your POW`
  const para = isSignUp ? `Please provide your email address or sign up using an existing provider listed below.` : `Enter the email address you signed up with or sign in with an existing provider.`

  const getForm = isSignUp
    ? <EmailForm setShowContent={setShowContent} loginEmail={loginEmail} />
    : <SignInForm />

  return (
    <>
      <PageTitle>
        <h1>{h1}</h1>
        <p>{para}</p>
      </PageTitle>

      {getForm}

      <Separator text='or' />

      <SocialSignins loginSocial={loginSocial}/>

      {/* <p className={`mx-auto`}>
        {isSignUp
          ? <>Already have an account? <a href='#' onClick={handleClick}>Log in</a></>
          : <>Create a new account? <a href='#' onClick={handleClick}>Sign up</a></>
        }
      </p> */}
    </>
  )
}

export default CreateAccountEmail