import React from 'react'

import css from './styles.module.scss'

const BgCircles = () => (
  <div className={css.circles}>
    <svg className={css.blob} width='833' height='893' viewBox='0 0 833 893' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_f_5124_390164)'>
      <path d='M712.802 404C712.802 524.398 492.202 664 371.804 664C251.406 664 506.701 534 432.804 418C346.804 283 336.803 167 444.803 120C565.201 120 712.802 283.602 712.802 404Z' fill='#FCFCFD'/>
      </g>
      <g filter='url(#filter1_f_5124_390164)'>
      <path d='M212.9 729.33C114.312 660.22 126.628 399.449 195.738 300.861C264.848 202.274 224.755 485.943 362.159 492.019C522.068 499.09 622.795 557.486 599.287 672.9C530.177 771.488 311.487 798.44 212.9 729.33Z' fill='#FCFCFD'/>
      </g>
      <defs>
      <filter id='filter0_f_5124_390164' x='219' y='0' width='613.802' height='784' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
      <feFlood floodOpacity='0' result='BackgroundImageFix'/>
      <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/>
      <feGaussianBlur stdDeviation='60' result='effect1_foregroundBlur_5124_390164'/>
      </filter>
      <filter id='filter1_f_5124_390164' x='21.4189' y='160.068' width='701.262' height='727.775' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
      <feFlood floodOpacity='0' result='BackgroundImageFix'/>
      <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/>
      <feGaussianBlur stdDeviation='60' result='effect1_foregroundBlur_5124_390164'/>
      </filter>
      </defs>
    </svg>
    <div className={`${css.circle} ${css.circle_lg}`} />
    <div className={`${css.circle} ${css.circle_md}`} />
    <div className={`${css.circle} ${css.circle_sm}`} />
  </div>
)

export default BgCircles